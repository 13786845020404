import React from 'react';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { Grid } from 'src/components/Grid';
import { Heading } from 'src/components/Heading';
import { MediaSectionContent } from 'src/components/MediaSection';
import { Section } from 'src/components/Section';

export const CommunityUserSection = props => {
    return (
        <Section vertical="lg" wrap bg="altBg" position="relative" {...props}>
            <Grid columns={[1, null, 2]} spacing={['spacing-xl', null, '80px']}>
                <MediaSectionContent kind="h3" title="For community users">
                    <Heading kind="h5" fontWeight="normal">
                        The DSA could...
                    </Heading>
                    <Box pt="spacing">
                        {[
                            {
                                title:
                                    'Provide more transparency and protection in content moderation',
                            },
                            {
                                title:
                                    'Regulate and make targeted advertising more transparent',
                            },
                            {
                                title:
                                    'Aid in creating a safer community space by allowing freedom of speech while also discourging harmful or illegal content',
                            },
                            {
                                title:
                                    'Prevent platforms from altering their algorithm based on your profile.',
                            },
                            {
                                title:
                                    'Avoid misleading messaging for specific services.',
                            },
                            {
                                title:
                                    'Require platforms to avoid purposely misleading design practices so you are not manipulated or deceived.',
                            },
                        ].map(highlight => (
                            <CheckListItem
                                circleIcon
                                key={highlight.title}
                                title={highlight.title}
                            />
                        ))}
                    </Box>
                </MediaSectionContent>
                <MediaSectionContent kind="h3" title="For community admins">
                    <Heading kind="h5" fontWeight="normal">
                        The DSA could require or need to consider...
                    </Heading>
                    <Box pt="spacing" position="relative" as="ul">
                        {[
                            {
                                title:
                                    'Providing transparency reports for online advertising and content moderation',
                            },
                            {
                                title:
                                    'Understanding and adapting targeted marketing strategies as they may be heavily regulated for better transparency or outright banned',
                            },
                            {
                                title:
                                    'Adding to or train moderation teams in best practices and how to better mitigate harmful or illegal content',
                            },
                            {
                                title:
                                    'Learning about technology available to make reporting and moderation easier and more effective',
                            },
                            {
                                title:
                                    'Carrying out a risk reduction analysis to monitor areas focused in illegal content, reduction of rights, user security, and mental and physical health.',
                            },
                            {
                                title:
                                    'Immediate removal of illegal products, services, or content.',
                            },
                        ].map(highlight => (
                            <CheckListItem
                                circleIcon
                                key={highlight.title}
                                title={highlight.title}
                                as="li"
                            />
                        ))}
                    </Box>
                </MediaSectionContent>
            </Grid>
        </Section>
    );
};
