import { Box } from '@audentio/stuff/src';
import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Anchor } from 'src/components/Anchor';
import { CenteredContent } from 'src/components/CenteredContent';
import { CheckListItemGroup } from 'src/components/CheckListItemGroup';
import { CommunityUserSection } from 'src/components/DigitalServiceAct/CommunityUserSection';
import { MediaSection } from 'src/components/MediaSection';
import { Methods } from 'src/components/Methods/Methods';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Section } from 'src/components/Section';
import { ThemeProvider } from 'src/components/ThemeProvider';

export default function DigitalServicesAct({ data }) {
    const what_is_digital_services_para =
        'The DSA was passed in March 2022 and will affect online communities including forums, discussion boards, social media sites (especially larger platforms), and other organizations that have users in the European Union regarding digital ads and moderation, particularly the accountability, and transparency of them, in addition to other topics.';
    return (
        <Layout
            pageTitleFull="Impact of the Digital Services Act on your Online Community"
            siteDescription="Online community management and moderation may be substantially effected if this act is passed."
            wrapChildren
            contactForm
        >
            <MethodsLandingHero
                title="Impact of the Digital Services Act on your online community"
                image={data.hero.childImageSharp.fluid}
                subtitle="Since passed, this act may have a substantial effect on how  your online community is managed and moderated. These measures aim to create a safe and trustworthy online space for people to engage."
            />
            <CenteredContent
                title="What is the Digital Services Act?"
                button={{
                    text: 'LEARN MORE',
                    href:
                        'https://ec.europa.eu/info/strategy/priorities-2019-2024/europe-fit-digital-age/digital-services-act-ensuring-safe-and-accountable-online-environment_en',
                }}
                py="spacing-xl"
                pb="spacing"
            >
                {what_is_digital_services_para}
            </CenteredContent>
            <Section pb={['40px', 'spacing-xl', '80px']}>
                <Box overflow="scroll">
                    <Img
                        style={{
                            width: '750px',
                            height: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                        fluid={{
                            ...data.articleTable.childImageSharp.fluid,
                            sizes: '(max-width: 800px)  800px',
                        }}
                        alt=""
                    />
                </Box>
                <Box d="flex" justifyContent="center" pt="spacing">
                    <Anchor
                        textDecoration="underline"
                        href="https://eur-lex.europa.eu/legal-content/en/TXT/?qid=1608117147218&uri=COM%3A2020%3A825%3AFIN"
                    >
                        EUR-Lex - 52020PC0825 - EN - EUR-Lex." EUR-Lex, 2020
                    </Anchor>
                </Box>
            </Section>

            <CommunityUserSection />

            <CenteredContent
                title="What is the timeline for Digital Services Act?"
                py="spacing"
                pt={['40px', 'spacing-xl', '80px']}
                pb="spacing"
            >
                The Digital Services Act (DSA) was passed in March 2022 and will
                require large platforms and search engines to more closely
                monitor illegal content on their platforms. Although the DSA has
                been passed, it will not go fully into affect until July 2023,
                but it's best to remain informed and make compliance changes as
                early as you can.
            </CenteredContent>

            <Section overflow="scroll" pb={['40px', 'spacing-xl', '80px']}>
                <Img
                    style={{
                        minWidth: '1200px',
                        maxWidth: '1200px',
                        height: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                    fluid={{
                        ...data.timeline.childImageSharp.fluid,
                        sizes: '(max-width: 800px)  800px',
                    }}
                    alt=""
                />
            </Section>
            <ThemeProvider defaultMode="dark">
                <MediaSection
                    image={data.movingForward.childImageSharp.fluid}
                    title="Takeaways for moving forward"
                    bg="primaryBackground"
                >
                    <CheckListItemGroup
                        items={[
                            {
                                title: 'change is happening',
                                description:
                                    'The trend towards data privacy and creating a safer online experience is growing along with discontent with large social media platforms.',
                            },
                            {
                                title: 'other regulations are developing',
                                description:
                                    'More regulations are being developed as well like the Digital Markets Act and the Online Safety Bill in the United Kingdom.',
                            },
                            {
                                title: 'align your community',
                                description:
                                    'Start preparing for and making changes that align with consumer desires and potential regulations.',
                            },
                        ]}
                    />
                </MediaSection>
            </ThemeProvider>
            <Methods bg="secondaryBackground" />
            <Section vertical="md" pt={[0, 0, 0]} wrap bg="secondaryBackground">
                <ClientList
                    title="Valued Partners"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderTop="1px"
                    borderColor="border"
                    pt={['spacing-lg', '40px', 'spacing-xl']}
                />
            </Section>
        </Layout>
    );
}

export const query = graphql`
    query digitalServicesActImages {
        hero: file(
            relativePath: { eq: "images/digital-services-act/hero-apps.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        articleTable: file(
            relativePath: {
                eq: "images/digital-services-act/article-table.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        timeline: file(
            relativePath: { eq: "images/digital-services-act/timeline.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        movingForward: file(
            relativePath: {
                eq: "images/digital-services-act/moving-forward.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
