import React from 'react';
import { Box } from '../Box';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { Section } from '../Section';
import { SmallSubtitle } from '../SmallSubtitle';

type CenteredContentTypes = {
    title?: String,
    children?: any,
    subtitle?: any,
    vertical?: string,
    kind?: String,
    button?: any,
};
export const CenteredContent = ({
    title,
    children,
    subtitle = null,
    vertical = 'lg',
    kind = 'h3',
    button = {},
    ...props
}: CenteredContentTypes) => {
    return (
        <Section vertical={vertical} {...props}>
            <Flex
                direction="column"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                w="100%"
                mx="auto"
                maxW={700}
            >
                {subtitle && (
                    <SmallSubtitle mb="spacing-sm">{subtitle}</SmallSubtitle>
                )}
                {title && (
                    <Heading
                        kind={kind}
                        as="h2"
                        mt={0}
                        mb="spacing"
                        textAlign="center"
                    >
                        {title}
                    </Heading>
                )}

                {children &&
                    (typeof children === 'string' ? (
                        <Paragraph
                            pb="spacing"
                            fontSize="body"
                            color="bodyText"
                            w="75%"
                        >
                            {children}
                        </Paragraph>
                    ) : (
                        children
                    ))}
                {button.text && (
                    <Box pt="spacing">
                        <Button href={button.href} external>
                            {button.text}
                        </Button>
                    </Box>
                )}
            </Flex>
        </Section>
    );
};
